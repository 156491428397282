import React, { FC } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import {
  useSettings,
  useStyles,
} from '@wix/yoshi-flow-editor/tpa-settings/react';
import { Divider, Text, TextTypography as TYPOGRAPHY } from 'wix-ui-tpa';

import { st, classes } from './CourseAvailability.st.css';
import { classes as serviceClasses } from '../ServiceInfo.st.css';
import settingsParams from '../../../../../settingsParams';
import { DataHooks } from '../consts';
import stylesParams from '../../../../../stylesParams';

interface Props {
  numberOfSpotsLeft?: number;
  checkDivider?: boolean;
  isTooLateToBook: boolean;
}

export const CourseAvailability: FC<Props> = ({
  numberOfSpotsLeft,
  checkDivider,
  isTooLateToBook,
}) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const styles = useStyles();
  const { isMobile } = useEnvironment();
  const isCourseAvailabilityVisible = settings.get(
    settingsParams.isCourseAvailabilityVisible,
  );

  const showFullyBooked =
    isCourseAvailabilityVisible && numberOfSpotsLeft! <= 0;

  const available =
    isCourseAvailabilityVisible &&
    numberOfSpotsLeft! > 0 &&
    isTooLateToBook === false;

  if (
    numberOfSpotsLeft === undefined ||
    !isCourseAvailabilityVisible ||
    !(showFullyBooked || available)
  ) {
    return null;
  }
  const displayNumberOfSpots =
    available && settings.get(settingsParams.displayNumberOfSpots);

  const withDivider =
    checkDivider && settings.get(settingsParams.isServiceDividerVisible);

  let customAvailabilityText: any;
  let defaultAvailabilityText: any;
  if (displayNumberOfSpots) {
    customAvailabilityText = settings.get(
      settingsParams.courseAvailabilityText,
    );
    customAvailabilityText =
      customAvailabilityText &&
      `${numberOfSpotsLeft} ${customAvailabilityText}`;
    defaultAvailabilityText = t(
      'service.schedule.course.number-of-spots-left',
      {
        numberOfSpotsLeft,
      },
    );
  } else if (available) {
    customAvailabilityText = settings.get(settingsParams.courseAvailableText);
    defaultAvailabilityText = t('service.schedule.course.available-spots');
  }
  const courseAvailability = showFullyBooked
    ? settings.get(settingsParams.courseNoAvailabilityText) ||
      t('service.schedule.course.fully-booked')
    : customAvailabilityText || defaultAvailabilityText;
  return (
    <>
      {withDivider ? (
        <Divider
          data-hook={DataHooks.DIVIDER2}
          className={serviceClasses.divider}
        />
      ) : null}
      <Text
        data-hook={DataHooks.COURSE_AVAILABILITY}
        className={st(classes.root, {
          isMobile,
        })}
        typography={TYPOGRAPHY.listText}
        tagName={styles.get(stylesParams.courseAvailabilityFont).htmlTag}
      >
        {courseAvailability}
      </Text>
    </>
  );
};
